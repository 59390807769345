import type { WritableAtom, useStore } from "jotai";
import { useHydrateAtoms } from "jotai/utils";

type AnyWritableAtom = WritableAtom<unknown, any[], any>;
type AtomTuple<A = AnyWritableAtom, V = unknown> = readonly [A, V];

export default function HydrateAtoms({
  initialValues,
  options = {},
  children,
}: {
  initialValues: Array<AtomTuple>;
  options?: Parameters<typeof useStore>[0];
  children: React.ReactNode;
}) {
  // initialising on state with prop on render here
  useHydrateAtoms(initialValues, options);
  return children;
}
